import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import {
  DioboxEvent, StripeAccount, AccountUser, User,
} from "sharedTypes"
import SecondaryNav from "components/layout/SecondaryNav"
import CenteredContent from "components/layout/CenteredContent"
import CustomDomains from "pages/EventShow/Settings/CustomDomains"
import TeamMembers from "pages/EventShow/Settings/TeamMembers"
import EventActivities from "pages/EventShow/Settings/EventActivities"
import TicketingPayments from "pages/EventShow/Settings/TicketingPayments"
import General from "pages/EventShow/Settings/General"
import EmailNotifications from "pages/EventShow/Settings/EmailNotifications"
import EmbedCode from "pages/EventShow/Settings/EmbedCode"
import BadgePrinting from "pages/EventShow/Settings/BadgePrinting"
import QRCodes from "pages/EventShow/Settings/QRCodes"
import { Flex } from "@chakra-ui/react"
import useFeatureFlag from "services/featureFlags"
import LeadRetrieval from "./LeadRetrieval/LeadRetrieval"

type Props = {
  event: DioboxEvent;
  updateEvent: (params: {}) => Promise<void>
  cloneEvent: () => Promise<void>
  deleteEvent: () => Promise<void>
  stripeAccounts: StripeAccount[]
  currentUser: User
  accountUsers?: AccountUser[]
}

const Settings = ({
  event, updateEvent, cloneEvent, deleteEvent, stripeAccounts, currentUser, accountUsers,
}: Props) => {
  const { path } = useRouteMatch()
  const { leadRetrieval: leadRetrievalEnabled } = useFeatureFlag()

  if (!accountUsers) {
    return null
  }

  return (
    <Flex
      flexGrow={1}
      position="relative"
      h="100%"
    >
      <SecondaryNav
        routeMatch="/events/:id/settings"
        items={[
          { icon: "settings-general", color: "blue.600", text: "General" },
          { icon: "settings-emails", color: "blue.400", text: "Email Notifications" },
          { icon: "settings-payments", color: "green.600", text: "Ticketing & Payments" },
          { icon: "settings-custom-domain", color: "teal.300", text: "Custom Email Domains" },
          { icon: "settings-check-in-sessions", color: "pink.500", text: "Event Activities" },
          { icon: "settings-qr-codes", color: "orange.700", text: "QR Codes" },
          { icon: "settings-badge-printing", color: "purple.700", text: "Badge Printing" },
          { icon: "settings-embed-code", color: "orange.300", text: "Embed Code" },
          ...leadRetrievalEnabled ? [{ icon: "settings-qr-codes", color: "blue.300", text: "Lead Retrieval" }] : [],
          { icon: "settings-team1", color: "pink.600", text: "Team" },
        ]}
      />
      <CenteredContent>
        <Switch>
          <Route
            path={`${path}/email-notifications`}
            exact
          >
            <EmailNotifications
              event={event}
              teamMembers={accountUsers}
              updateEvent={updateEvent}
            />
          </Route>
          <Route
            path={`${path}/ticketing-payments`}
            exact
          >
            <TicketingPayments
              eventId={event.id}
              currency={event.currency}
              currencySelectDisabled={event.counts.ticketOrders > 0}
              stripeAccounts={stripeAccounts}
              stripeAccountId={event.stripeAccountId || ""}
              updateEvent={updateEvent}
            />
          </Route>
          <Route
            path={`${path}/custom-email-domains`}
            exact
          >
            <CustomDomains
              event={event}
              updateEvent={updateEvent}
              enabled={event.whiteLabel}
            />
          </Route>
          <Route
            path={`${path}/embed-code`}
            exact
          >
            <EmbedCode event={event} />
          </Route>
          <Route
            path={`${path}/event-activities`}
            exact
          >
            <EventActivities
              checkInLocations={event.checkInLocations}
              enabled={event.checkInLocationsEnabled}
            />
          </Route>
          <Route
            path={`${path}/qr-codes`}
            exact
          >
            <QRCodes enabled={event.qrCodesEnabled} />
          </Route>
          <Route
            path={`${path}/badge-printing`}
            exact
          >
            <BadgePrinting enabled={event.badgePrintingEnabled} />
          </Route>
          <Route
            path={`${path}/team`}
            exact
          >
            <TeamMembers
              currentEvent={event}
              maxTeamSize={event.maxTeamSize}
              currentUser={currentUser}
              accountUsers={accountUsers}
            />
          </Route>
          <Route
            path={`${path}/lead-retrieval`}
            exact
          >
            <LeadRetrieval event={event} />
          </Route>
          <Route
            path=""
          >
            <General
              event={event}
              updateEvent={updateEvent}
              cloneEvent={cloneEvent}
              deleteEvent={deleteEvent}
            />
          </Route>
        </Switch>
      </CenteredContent>
    </Flex>
  )
}

export default Settings
