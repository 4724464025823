import React from "react"
import TextField from "components/ReactHookForm/TextField"
import { Control, FieldValues } from "react-hook-form"
import { Box, Stack, Text } from "@chakra-ui/react"
import PaymentField from "components/ReactHookForm/PaymentField"
import PreventPasswordAutofill from "components/PreventPasswordAutofill"
import InfoSegment from "../InfoSegment"
import SegmentTitle from "../SegmentTitle"

type Props<T extends FieldValues> = {
  control: Control<T, object>
  emailAddress: string
  leadRetrievalLicenseFee: string
}

const PersonalDataScreen = <T extends FieldValues, >({
  control,
  emailAddress,
  leadRetrievalLicenseFee,
}: Props<T>) => (
  <>
    <InfoSegment />
    <Stack>
      <Stack spacing={4} mb={4}>
        <Text fontWeight="semibold">
          {emailAddress}
        </Text>
        <Text>
          Complete your account setup with this email address by entering your exhibitor name
          and creating a password. After your license fee payment is confirmed, you can login
          to the Lead Retrieval App to access the event.
        </Text>
      </Stack>
      <PreventPasswordAutofill />
      <TextField
        control={control}
        name="name"
        vertical
        variant="outline"
        label="Exhibitor Name"
        placeholder="ACME Corp"
        helperText="In most cases, this should be the name of your company or team. You can change this later."
        showAsterisk
      />
      <TextField
        control={control}
        name="password"
        vertical
        variant="outline"
        label="Create Password"
        placeholder=""
        helperText="Must contain at least 6 characters."
        type="password"
        showAsterisk
      />
    </Stack>
    <SegmentTitle leftText="License Fee" rightText={`$${Number(leadRetrievalLicenseFee).toFixed(2)}`} />
    <Box borderWidth={1} borderRadius="md" p={3}>
      <PaymentField name="paymentCompleted" control={control} />
    </Box>
  </>
  )

export default PersonalDataScreen
