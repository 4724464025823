import {
  del, get, post, put,
} from "services/api"
import { currentAccountId } from "services/authentication"
import {
  CheckInLocation,
  CheckLeadRetrievalUserEmailResponse,
  CreateLeadRetrievalUserResponse,
  CustomDomain,
  DioboxEvent,
  EventGroup,
  EventUser,
  LeadRetrievalUser,
  PublicDioboxEvent,
} from "sharedTypes"

export const getEvents = (dateFilter: string | null) => {
  const params = dateFilter ? { time: dateFilter } : {}

  return get<DioboxEvent[]>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events.json`, params)
}

export const getEvent = (eventId: string) => get<DioboxEvent>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}.json`)

export const getEventCounts = (eventId: string) => get<DioboxEvent["counts"]>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/counts.json`)

export const postEvent = (params: DioboxEvent) => post<DioboxEvent>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events.json`, params)

export const putEvent = (eventId: string, event: DioboxEvent) => put<DioboxEvent>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}.json`, event)

export const cloneEvent = (eventId: string) => post<void>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/clone.json`)

export const deleteEvent = (eventId: string) => del<void>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}.json`)

export const putEventGroup = (eventId: string, eventGroup: Partial<EventGroup>) => put<EventGroup>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/event_groups/${eventGroup.id}.json`, eventGroup)

export const postEventGroup = (eventId: string, eventGroup: Partial<EventGroup>) => post<EventGroup>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/event_groups.json`, eventGroup)

export const deleteEventGroup = (eventId: string, guestList: EventGroup) => del<void>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/event_groups/${guestList.id}.json`)

export const postEventUser = (eventId: string, eventUser: Partial<EventUser>) => post<EventUser>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/team_memberships.json`, eventUser)

export const putEventUser = (eventId: string, eventUserId: number, eventUser: Partial<EventUser>) => put<EventUser>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/team_memberships/${eventUserId}.json`, eventUser)

export const deleteEventUser = (eventId: string, eventUserId: number) => del<void>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/team_memberships/${eventUserId}.json`)

export const postCustomDomain = (eventId: string, customDomain: CustomDomain) => post<CustomDomain>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/custom_domains.json`, { customDomain })

export const deleteCustomDomain = (eventId: string, customDomainId: number) => del<void>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/custom_domains/${customDomainId}.json`)

export const checkCustomDomain = (eventId: string, customDomainId: number) => post<CustomDomain>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/custom_domains/${customDomainId}/check.json`)

export const postCheckInLocation = (eventId: string, checkInLocation: CheckInLocation) => post<CheckInLocation>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/check_in_locations.json`, checkInLocation)

export const putCheckInLocation = (eventId: string, checkInLocationId: number, checkInLocation: CheckInLocation) => put<CheckInLocation>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/check_in_locations/${checkInLocationId}.json`, checkInLocation)

export const deleteCheckInLocation = (eventId: string, checkInLocationId: number) => del<void>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/check_in_locations/${checkInLocationId}.json`)

export const getPublicEvent = (eventExternalId: string) => get<PublicDioboxEvent>(`${import.meta.env.VITE_API_BASE}/api/v3/public/events/${eventExternalId}.json`, { bypass404redirection: true })

export const checkLeadRetrievalUserEmail = (eventExternalId: string, email: string) => get<CheckLeadRetrievalUserEmailResponse>(`${import.meta.env.VITE_API_BASE}/api/v3/public/events/${eventExternalId}/lead_retrieval_users/check_email.json`, { email })

export const createLeadRetrievalUser = (eventExternalId: string, user: LeadRetrievalUser) => post<CreateLeadRetrievalUserResponse>(`${import.meta.env.VITE_API_BASE}/api/v3/public/events/${eventExternalId}/lead_retrieval_users.json`, { user })
