import React from "react"
import {
  Box, Heading, Stack, Text,
} from "@chakra-ui/react"
import Link from "components/elements/Link"
import InfoSegment from "../InfoSegment"

type Props = {
  email: string
}

const PaymentSuccessScreen = ({ email }: Props) => (
  <>
    <InfoSegment />
    <Stack spacing={5} textAlign="center">
      <Heading fontWeight="semibold" fontSize="3xl">Your license is activated!</Heading>
      <Box mt={2} mb={8}>
        <Text><b>{email}</b></Text>
      </Box>
      <Text>To start scanning leads, download the Android and iOS apps: <Link isExternal href="https://help.d.io/en/articles/10312711-download-lead-retrieval-apps">https://help.d.io/en/articles/10312711-download-lead-retrieval-apps</Link>
      </Text>
    </Stack>
  </>
)

export default PaymentSuccessScreen
