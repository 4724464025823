import LeadRetrievalRegister
, { Step } from "pages/Unauthenticated/LeadRetrieval/Register/LeadRetrievalRegister"
import { usePublicEvent } from "queries/events"
import Spinner from "components/elements/Spinner"
import { Center, Heading } from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import StripeProvider from "components/StripeProvider"
import { StripeElementsOptions } from "@stripe/stripe-js"

const LeadRetrievalRegisterWrapper = () => {
  const { eventId } = useParams<{ eventId: string, subpath: Step }>()
  const { data: event, isLoading } = usePublicEvent(eventId)

  if (isLoading) {
    return <Spinner />
  }

  if (!event) {
    return <Center h="100vh"><Heading>Event Not Found</Heading></Center>
  }

  const options = {
    mode: "payment",
    currency: "usd",
    amount: Number(event.leadRetrievalLicenseFee) * 100,
  } as StripeElementsOptions

  return (
    <StripeProvider options={options}>
      <LeadRetrievalRegister event={event} />
    </StripeProvider>
  )
}

export default LeadRetrievalRegisterWrapper
