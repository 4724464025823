import { Flex, Heading } from "@chakra-ui/react"
import React from "react"

type Props = {
  leftText: string,
  rightText?: string,
}

const SegmentTitle = ({ leftText, rightText }:Props) => (
  <Flex
    backgroundColor="gray.50"
    alignItems="center"
    mx={-8}
    px={8}
    py={6}
    borderTopWidth={1}
    borderBottomWidth={1}
    justifyContent="space-between"
  >
    <Heading size="md" fontWeight="semibold">{leftText}</Heading>
    {rightText && <Heading size="md" fontWeight="semibold">{rightText}</Heading>}
  </Flex>
)

export default SegmentTitle
