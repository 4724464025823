import React from "react"
import {
  Box, Button, Flex, Image, Link, Text,
} from "@chakra-ui/react"
import logo from "assets/images/diobox-icon.svg"
import { iOSAppURL, AndroidAppURL } from "utilities/routes"
import AppDownloadLinks from "components/AppDownloadLinks"
import { removeProtocolFromUrl } from "../utilities/strings"

type Props = {
  onClickForceDesktopView: () => void
}

const DownloadApp = ({ onClickForceDesktopView }: Props) => (
  <Flex
    justify="center"
    align="center"
    h="100dvh"
  >
    <Box w="350px" textAlign="center" p={4}>
      <Image src={logo} display="inline-block" w="60px" mb={2} />
      <Text fontSize="2xl" mb={2}>Download the App!</Text>
      <Text mb={6}>
        Download the Diobox iOS or Android app or use <Link textDecoration="underline" href={import.meta.env.VITE_BASE} isExternal>{removeProtocolFromUrl(import.meta.env.VITE_BASE)}</Link> from a desktop browser.
      </Text>
      <AppDownloadLinks iOSAppURL={iOSAppURL} AndroidAppURL={AndroidAppURL} />
      <Text mt={2}>
        <Button onClick={onClickForceDesktopView} textDecoration="underline" variant="shadow">Continue using web app</Button>
      </Text>
    </Box>
  </Flex>
)

export default DownloadApp
