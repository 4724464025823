import React from "react"
import TextField from "components/ReactHookForm/TextField"
import { Control, FieldValues } from "react-hook-form"
import InfoSegment from "../InfoSegment"

type Props<T extends FieldValues> = {
  control: Control<T, object>
  onKeyDown: (event: React.KeyboardEvent) => void
}

const EmailScreen = <T extends FieldValues, >({ control, onKeyDown }: Props<T>) => (
  <>
    <InfoSegment />
    <TextField
      control={control}
      name="email"
      vertical
      variant="outline"
      label="Email Address"
      placeholder="john.smith@acmecorp.com"
      showAsterisk
      onKeyDown={onKeyDown}
    />
  </>
)

export default EmailScreen
