import React from "react"
import { Text, Link } from "@chakra-ui/react"
import { DIOBOX_LEGAL_LINK, DIOBOX_PRIVACY_LINK } from "utilities/externalLinks"

const TOULinks = () => (
  <Text align="center" fontSize="sm" pt={1}>
    By clicking this button, you agree to the<br />
    Diobox <Link textDecoration="underline" href={DIOBOX_LEGAL_LINK} isExternal>Terms of Use</Link>
    <> and </><Link textDecoration="underline" href={DIOBOX_PRIVACY_LINK} isExternal>Privacy Policy</Link>.
  </Text>
)

export default TOULinks
