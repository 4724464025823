import { Dispatch, ReactNode } from "react"
import { BuilderBlock } from "sharedTypes/builder"
import { EventUserRole } from "services/permissions"
import { Boolean as StringBoolean } from "utilities/enums"

export enum ModalName {
  Placeholder,
  EnrichDisabled,
  EventImage,
  ExportGuests,
  ExportInvitationList,
  ImportGuests,
  NewEmail,
  NewGuest,
  NewPlusOnes,
  GuestListForm,
  SegmentForm,
  SendTestEmail,
  SwitchAccount,
  TableForm,
  UpdateGuestStatus,
  Upgrade,
  WebPageURL,
  AccountUserForm,
  SelectGuest,
  FormForm,
  QuestionForm,
  AddFormQuestion,
  EditMessaging,
  NewTextMessage,
  ScheduleSendEmail,
  EventActivityForm,
  EditRecipients,
  PreviewTextMessage,
  EditTextRecipients,
  EditEmailName,
  EditTextMessageName,
  ExportTextReport,
  InviteExhibitorOrAssignLicense,
}

export type CurrentModal = {
  name: ModalName
  params?: { id: string }
}

export type GlobalState = {
  currentAccount: Account;
}

export type Context = GlobalState & {
  dispatch: Dispatch<{ type, data }>;
}

export type User = {
  id: string;
  authenticationToken: string;
  firstName: string;
  lastName: string;
  email: string;
  photoUrl: string;
  accounts: Account[];
  confirmed: boolean;
  supportLogin: boolean;
  leadRetrievalUser: boolean;
}

export type StripeAccount = {
  id: number;
  accountId: string;
  stripeId: string;
  businessName: string | null;
}

export type Account = {
  id: string;
  cardBrand?: string;
  cardLast4?: string;
  name: string;
  admin: boolean;
  canCreateEvents: boolean;
  owner: boolean;
  industry: string;
  logoUrl: string;
  subscription: Subscription | null;
  customDomains: CustomDomain[];
  emailEnabled: boolean;
  dailyEmailLimit: number;
  dailyTextLimit: number;
  featureFlags: { [x: string]: boolean };
  stripeAccounts: StripeAccount[];
  disabled: boolean;
}

export type CustomDomain = {
  id: number;
  domain: string;
  dkim: { error: string | null, valid: boolean };
  dkim2: { error: string | null, valid: boolean };
  dmarc: { error: string | null, valid: boolean };
  spf: { error: string | null, valid: boolean };
  lastTestedAt: string | null;
  verifiedAt: string | null;
  verifyTxtKey: string | null;
  legacyAuthenticated: boolean | null;
  validSigning: boolean;
  mailbox: string;
}

export enum SubscriptionStatuses {
  Active = "active",
  PastDue = "past_due"
}

export enum SubscriptionType {
  Basic = "Basic",
  Standard = "Standard",
  Professional = "Professional",
  Corporate = "Corporate",
}

export type Subscription = {
  type: SubscriptionType;
  cancelAtPeriodEnd: boolean;
  amount: number;
  interval: "month" | "year";
  licenses: number;
  periodStartsAt: string;
  periodEndsAt: string;
  stripeId: string | null;
  expired: boolean;
  legacy: boolean;
  status: SubscriptionStatuses;
  hostedInvoiceUrl?: string | null;
}

export type EventCounts = {
  guests: number
  confirmedGuests: number
  waitlistedGuests: number
  declinedGuests: number
  unconfirmedGuests: number
  arrivedGuests: number
  unarrivedGuests: number
  seatedGuests: number
  unseatedGuests: number
  tables: number
  seats: number
  assignedSeats: number
  unassignedSeats: number
  waitlistedSeats: number
  grossSales: number
  netSales: number
  ticketOrders: number
  ticketedGuests: number
  tickets: number
  emails: number
  openedEmails: number
  hardBouncedEmails: number
  softBouncedEmails: number
  bouncedEmails: number
  clickedEmails: number
  dailyEmails: number
  dailyTexts: number
  eventGroups: {id: number, size: number}[]
  segments: []
  forms: number
  questions: number
  responses: number
  formSubmissions: number
  formSubmissionLineItems: {
    registration?: number
    rsvp?: number;
    ticket?: number;
    waitlist?: number;
  }
  texts: number
}

export type DioboxEvent = {
  id: string
  addressLine1: string
  addressLine2: string
  checkInLocations: CheckInLocation[]
  city: string
  country: string
  counts: EventCounts
  currency: string
  customDomain: CustomDomain|null
  deferredTicketAssignmentEnabled: boolean
  emailNotificationsCustomMessage: string|null
  emailNotificationsEventName: string
  emailNotificationsReplyTo: string
  emailNotificationsSenderMailbox: string|null
  emailNotificationsSenderName: string
  emailNotificationsUserIds: string[]
  endDate: string|null
  eventGroups: EventGroup[]
  segments: Segment[]
  users: EventUser[]
  guestCount: number
  maxTeamSize: number
  maxTables: number|null
  nonTicketedGuestLimit: number|null
  agendaEnabled: boolean
  checkInLocationsEnabled: boolean
  privateEmailsEnabled: boolean
  publicEmailsEnabled: boolean
  enhanceEnabled: boolean
  startDate: string
  state: string
  tables: Table[]
  timezone: string
  title: string
  venue: string
  website: Website
  zipcode: string
  currentUserRole: EventUserRole
  whiteLabel: boolean
  notificationsEnabled: boolean
  badgePrintingEnabled: boolean
  segmentsEnabled: boolean
  qrCodesEnabled: boolean
  guestGroupsEnabled: boolean
  ccEmailsEnabled: boolean
  sendConfirmationEmails: boolean
  translations: Translations
  portalBackgroundColor: string
  portalDateTimeLocation: string
  portalEventImageUrl: string|null
  portalLogoUrl: string|null
  portalMapUrl: string
  portalPrimaryColor: string
  portalShowEventImage: boolean
  portalShowLogo: boolean
  portalShowMap: boolean
  portalTopNavBackgroundColor: string
  portalTopNavTextColor: string
  externalId: string
  portalEventImageScale: number
  portalPageHeader: string
  portalShowPageHeader: boolean
  portalCaptchaEnabled: boolean
  includePassbook: boolean
  includeQrCode: boolean
  stripeAccountId: string | null
  leadRetrievalLicenseFee: string
}

export type PublicDioboxEvent = {
  attachIcs: boolean
  guestsCanUpdateProfile: boolean
  translations: {
    addressLine1: string
    addressLine2: string
    buttonNotWorking: string
    city: string
    company: string
    confirmed: string
    continue: string
    country: string
    declined: string
    edit: string
    editProfile: string
    editProfileHeader: string
    edited: string
    emailAddress: string
    emailNotifications: string
    eventActivity: string
    firstName: string
    fullName: string
    guest: string
    lastName: string
    payment: string
    phone: string
    plusOnesHeader: string
    postalCode: string
    qrCodeForCheckIn: string
    receipt: string
    registerGuestsNow: string
    registrationClosingMessage: string
    registrationEditMessage: string
    registrationSubmitButton: string
    registrationSuccessMessage: string
    rsvpClosingMessage: string
    rsvpConfirm: string
    rsvpDecline: string
    rsvpEditMessage: string
    rsvpPlusOneLabel: string
    rsvpPlusOneNo: string
    rsvpPlusOneYes: string
    rsvpSubmitButton: string
    rsvpSuccessMessage: string
    save: string
    submitted: string
    surveyClosingMessage: string
    surveyEditMessage: string
    surveySubmitButton: string
    surveySuccessMessage: string
    state: string
    ticketClosingMessage: string
    ticketEditMessage: string
    ticketSubmitButton: string
    ticketSuccessMessage: string
    title: string
    unconfirmed: string
    uploadPhoto: string
    viewMap: string
    waitlistClosingMessage: string
    waitlistEditMessage: string
    waitlistSubmitButton: string
    waitlistSuccessMessage: string
    waitlisted: string
    yourInformation: string
    yourInformationDescription: string
  }
  accountName: string
  forms: {
    id: string
    name: string
    description: string | null
    price: string
    currency: string
    limitPerSubmission: number
    variablePrice: boolean
    formData: {
      blocks: {
        type: string
        data: {
          id: string
          title: string
          choices?: string[]
          type: string
          required: boolean
        }
      }[]
    }
    type: string
    closed: boolean
    profileAddressEnabled: boolean
    profileAddressRequired: boolean
    profileCityEnabled: boolean
    profileCityRequired: boolean
    profileCompanyEnabled: boolean
    profileCompanyRequired: boolean
    profileCountryEnabled: boolean
    profileCountryRequired: boolean
    profilePhoneEnabled: boolean
    profilePhoneRequired: boolean
    profilePostalCodeEnabled: boolean
    profilePostalCodeRequired: boolean
    profileStateEnabled: boolean
    profileStateRequired: boolean
    profileTitleEnabled: boolean
    profileTitleRequired: boolean
    profileNameEnabled: boolean
    profileEmailEnabled: boolean
  }[]
  stripeAccountId: string | null
  whiteLabel: boolean
  language: string
  leadRetrievalLicenseFee: string
  id: string
  deferredAssignmentEnabled: boolean
  name: string
  ics: string
  backgroundColor: string
  captchaEnabled: boolean
  dateTimeLocation: string
  eventImageUrl: string
  eventImageScale: number
  logoUrl: string
  mapUrl: string
  pageHeader: string
  primaryColor: string
  showEventImage: boolean
  showLogo: boolean
  showMap: boolean
  showPageHeader: boolean
  topNavBackgroundColor: string
  topNavTextColor: string
}

export type CheckLeadRetrievalUserEmailResponse = {
  existingLicense: boolean
  user: {
    email: string
    id: string
    firstName: string
    lastName: string
  }
}

export type LeadRetrievalUser = {
  id?: string
  email?: string
  password?: string
  name?: string
  eventId?: string
}

export type CreateLeadRetrievalUserResponse = {
  id: number
  paymentIntentClientSecret: string
  paymentIntentStatus: string
}

export type Translations = {
  addressLine1: string
  addressLine2: string
  city: string
  company: string
  confirmed: string
  continue: string
  country: string
  declined: string
  editProfile: string
  editProfileHeader: string
  emailAddress: string
  emailNotifications: string
  firstName: string
  fullName: string
  lastName: string
  payment: string
  phone: string
  postalCode: string
  qrCodeForCheckIn: string
  registerGuestsNow: string
  registrationClosingMessage: string
  registrationEditMessage: string
  registrationSubmitButton: string
  registrationSuccessMessage: string
  rsvpClosingMessage: string
  rsvpConfirm: string
  rsvpDecline: string
  rsvpEditMessage: string
  rsvpPlusOneLabel: string
  rsvpPlusOneNo: string
  rsvpPlusOneYes: string
  rsvpSubmitButton: string
  rsvpSuccessMessage: string
  save: string
  state: string
  ticketClosingMessage: string
  ticketEditMessage: string
  ticketSubmitButton: string
  ticketSuccessMessage: string
  title: string
  unconfirmed: string
  uploadPhoto: string
  viewMap: string
  waitlistClosingMessage: string
  waitlistEditMessage: string
  waitlistSubmitButton: string
  waitlistSuccessMessage: string
  waitlisted: string
  yourInformation: string
  yourInformationDescription: string
  eventActivity: string
  surveySubmitButton: string
  surveySuccessMessage: string
  surveyEditMessage: string
  surveyClosingMessage: string
  edit: string
  edited: string
  guest: string
  plusOnesHeader: string
  submitted: string
  receipt: string
  buttonNotWorking: string,
}

export type EventUser = {
  id: number
  email: string
  role: EventUserRole
  owner: boolean
  user: User
  event: Partial<DioboxEvent>
  confirmed: boolean
  photoUrl: string
  name: string
}

export enum TableShapes {
  Circle = "circle",
  Row = "row",
  HalfRound = "half-round",
  Square = "square",
  Rectangle = "rectangle",
}

export type Table = {
  id: number;
  externalId: string;
  name: string;
  notes: string | null;
  topSize: number;
  bottomSize: number;
  rightSize: number;
  leftSize: number;
  seats: Seat[];
  shape: TableShapes;
  waitlistedGuestIds: string[];
  waitlistedGuests: GuestSummary[];
}

export type Seat = {
  number: number;
  guestId: string | null;
  reserved: boolean;
  guest: GuestSummary | null;
  description: string | null;
}

export enum QuestionTypes {
  SingleAnswer = "Single Answer",
  MultipleAnswer = "Multiple Answer",
  DropdownMenu = "Dropdown Menu",
  Terms = "Terms",
  SingleLineText = "Single Line Text",
  ParagraphText = "Paragraph Text",
  FileUpload = "File Upload",
  TextBlock = "Text Block",
}

export type FormQuestion = {
  id: string;
  title: string;
  choices: string[];
  type: QuestionTypes;
  required: boolean;
  responses: {
    [key: string]: number
  }
  isPlaceholder: boolean
}

export type EventQuestionResponse = {
  guestId: string
  name: string
  email: string
  photoUrl: string
  response: string
}
export enum WebsiteActionBehavior {
  RegisterForEvent = "tickets",
  ApplyToAttend = "waitlist",
}

export type Website = {
  content: string;
  coverImageUrl: string;
  dateEnabled: boolean;
  dateHeader: string;
  dateLine1: string;
  dateLine2: string;
  links: string[];
  linksEnabled: boolean;
  locationEnabled: boolean;
  locationVenue: string;
  locationLine1: string;
  locationLine2: string;
  locationLine3: string;
  mapEnabled: boolean;
  published: boolean;
  previewToken: string;
  templateId: number | null;
  title: string;
  urlPath: string;
  viewsCount: number;
  speakers: Speaker[];
  agendaRows: AgendaRows;
  rsvpHeaderEventLogoUrl: string;
  rsvpHeaderTitle: string;
  rsvpHeaderLine1: string;
  rsvpHeaderLine2: string;
  rsvpProfileAddressEnabled: boolean;
  rsvpProfileAddressRequired: boolean;
  rsvpProfileBirthDateEnabled: boolean;
  rsvpProfileBirthDateRequired: boolean;
  rsvpProfileCityEnabled: boolean;
  rsvpProfileCityRequired: boolean;
  rsvpProfileCompanyEnabled: boolean;
  rsvpProfileCompanyRequired: boolean;
  rsvpProfileCountryEnabled: boolean;
  rsvpProfileCountryRequired: boolean;
  rsvpProfilePhoneEnabled: boolean;
  rsvpProfilePhoneRequired: boolean;
  rsvpProfilePostalCodeEnabled: boolean;
  rsvpProfilePostalCodeRequired: boolean;
  rsvpProfileStateEnabled: boolean;
  rsvpProfileStateRequired: boolean;
  rsvpProfileTitleEnabled: boolean;
  rsvpProfileTitleRequired: boolean;
  rsvpButtonColor: string;
  actionEnabled: boolean;
  actionBehavior: WebsiteActionBehavior;
  actionText: string;
}

export type AgendaRows = AgendaRow[]

export type AgendaRow = (AgendaHeading & AgendaItem) & {type: string;}

export type AgendaHeading = {
  id: number;
  title: string;
  description: string;
  startsAt: string;
}

export type AgendaItem = {
  id: number;
  title: string;
  description: string;
  speakerIds: number[];
  startsAt: string;
  endsAt: string;
}

export type Speaker = {
  id: number;
  name: string;
  company: string;
  title: string;
  description: string;
  link: string;
  photoUrl: string;
  position: number;
};

export type EventGroup = {
  id: number;
  icon: string | null;
  title: string;
  notifications: Notification[];
}

export type Email = {
  id: number
  eventId: string
  emailData: { blocks: BuilderBlock[] }
  from: string
  fromMailbox: string | null
  replyTo: string
  sentFromEmail: string
  opened: number
  sendTo: {}
  sendToUniqueEmails: boolean
  sentOn: string | null
  sendScheduledAt: string | null
  sentSize: number
  subject: string
  title: string
  emailAttachment: { fileName: string | null; fileSize: number | null; url: string; }
  showEmailAttachment: boolean
  showUnsubscribe: boolean
  createdAt: string
  updatedAt: string
  backgroundColor: string
  borderColor: string
  hasForm: boolean
  bodyColor: string
  bounced: number
  softBounced: number
  hardBounced: number
  clicks: number
  complained: number
  eventTitle: string
  noResponse: number
  responseCount: number
  responses: number
  sent: number
  unopened: number
}

export enum GuestStatus {
  Unconfirmed = 0,
  Confirmed = 1,
  Declined = 2,
  Waitlisted = 3,
}

export type SeatingAssignment = {
  guestId: string;
  id: number;
  seat: number;
  seatingTableId: number;
  seatingTableTitle: string;
  shape: string;
} | null

export type WaitlistTable = {
  id: number;
  name: string;
} | null

export type Notification = {
  email: boolean;
  id: number;
  userId: string;
}

export type GuestSummary = {
  id: string
  name: string
  checkedIn: number
  status: GuestStatus
  photoExists: boolean
  photoUrl: string
  personInfo: PersonInfo
  seatingAssignment: SeatingAssignment
  waitlistTable: WaitlistTable
  standing: boolean
  groupSize: number
  plusOnes?: GuestSummary[]
  guestId?: string
  externalId: string
}

export type FormSubmissionLineItemResponse = {
  question: string, response: string | string[]
}

export type FormSubmissionLineItem = {
  externalId: string
  createdAt: string
  currency: string
  formType: FormType
  id: string
  name: string
  price: string
  formSubmission: {
    eventId: string
    externalId: string
    paymentMethod: string
    paymentDetails: string
  }
  guestId: string
  responses: FormSubmissionLineItemResponse[]
}

type TextActivityItemData = {
  to: string
  sid: string
  body: string
  from: string
  status: string
  textId: number
  startDate: string
  date: string
}

export type TextActivityItem = {
  id: number
  textId: string
  title: string
  eventId: string
  eventTitle: string
  guestId: string
  personId: string
  transactionDate: string
  type: string
  displayType: string
  details: any
  userId: string
  createdAt: string
  updatedAt: string
  date: string
  data: TextActivityItemData
}

export type PersonInfo = {
  averageRating: number
  birthDate: string
  companyName: string
  companyTitle: string
  customFields: CustomField[]
  email: string|null
  emailHardBounced: boolean
  emailSoftBounced: boolean
  firstName: string
  gender: string
  id: string
  lastName: string
  noShows: number
  notes: string
  photoUrl: string
  totalAttended: number
  totalConfirmed: number
  totalEvents: number
  totalSpent: number
  transactions: Transaction[]
  unsubscribed: boolean
  formSubmissionLineItems: FormSubmissionLineItem[]
}

export type Guest = {
  id: string
  externalId: string
  checkedIn: number
  eventGroups: EventGroup[]
  eventId: string
  genderUnknownGuests: number
  girlGuests: number
  guyGuests: number
  notifications: Notification[]
  seatingAssignment: SeatingAssignment
  status: GuestStatus
  personInfo: PersonInfo
  waitlistTable: WaitlistTable
  standing: boolean
  group: GuestSummary[]
  questionsAndAnswers: []
  confirmedTime?: string
  createdTime: string
  updatedAt: string
}

export enum CustomImportGuestHeaderType {
  EmailField = "EmailField",
  PhoneField = "PhoneField",
  TextField = "TextField",
}

export type ImportGuestsHeader = {
  key: string;
  value: string;
  custom?: boolean;
  type?: CustomImportGuestHeaderType;
}

export type Transaction = {
  id: number;
  type: string;
  displayType: string;
  title: string|null;
  eventId: string;
  guestId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  transactionDate: string;
  data?: any;
  details?: string;
}

export type EmailData = {
  opened: string;
  clicked: string;
  bounced: "true" | "false";
  rsvp: "yes" | "no";
}

export type EmailTransaction = Transaction & {
  emailId: number;
  data: EmailData;
}

export type CheckInTransaction = Transaction & {
  checkInLocationId: number | null;
  guestOfGuest: boolean;
  checkInTime: string;
}

export type ActivityItem = (
  Transaction | EmailTransaction | CheckInTransaction | FormSubmissionLineItem | TextActivityItem
) & {
  id: string
  type: "AttendanceTransaction" | "EmailTransaction" | "NoteTransaction" | "FormSubmission" | "TextTransaction";
  displayType: string;
  title: string;
  date: string;
}

export type Address = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export type CustomField = {
  type: string;
  title: string;
  value: Address | string | {};
  id?: number;
  _destroy?: boolean;
  deleted?: boolean;
  default: boolean|null;
}

export type CheckInLocation = {
  id: number;
  name: string;
  icon: string;
}

export type Plan = {
  type: "Free" | "Standard" | "Basic" | "Professional" | "Corporate",
  stripePriceIds: { month: string, year: string }
  monthlyPrice: number,
  annualPrice: number,
  eventLimit: number | null,
  guestLimit: number | null,
  teamLimit: number,
  tableLimit: number | null,
  checkInAlerts: boolean,
  checkInLocations: boolean,
  qrCodes: boolean,
  enrichGuests: boolean,
  standardEmails: boolean,
  privateEmails: boolean,
  whiteLabelEmails: boolean,
  speakerProfiles: boolean,
  eventAgenda: boolean,
  realTimeVacancy: boolean,
  shareTablesExternally: boolean,
  downgradeText: string,
  downgradeConfirmations: string[];
  upgradeText: string;
  upgradeFeatures: [{icon: string, text: string}];
  features: string[];
}

export const ItemTypes = {
  GUEST: "guest",
}

export enum SegmentRuleField {
  CheckInDate = "Check-In Date",
  CheckInStatus = "Check-In Status",
  Confirmation = "Confirmation",
  EmailCampaign = "Email Campaign",
  GuestList = "Guest List",
  Seating = "Seating",
  Email = "Email",
  CreatedDate = "Created Date",
  EmailsSent = "Emails Sent",
  PlusOnesCount = "Plus-Ones Count",
}

export type Segment = {
  id: string,
  name: string,
  color: string,
  rules: {
    field: SegmentRuleField,
    operator: "eq" | "ne" | "gt" | "lt",
    value: string,
  }[],
  count: number,
}

export enum GuestFilterType {
  All = "All",
  EventGroup = "Guest List",
  DefaultSegment = "Default Segment",
  CustomSegment = "Custom Segment",
}

export type GuestFilter = {
  type: GuestFilterType;
  groupId?: string | number;
  segmentId?: string;
  icon?: string;
  iconColor?: string;
  label: string;
  count: number;
}

export type ImportData = {
  rowCount: number;
  headers: boolean;
  examples?: (string | undefined)[][];
  importFileId?: number | null;
}

export type APIError = {
  userMessage: string
}

export type ChargeConfiguration = {
  description: string;
  amount: number;
  plan: string;
  periodStartsAt: string;
  periodEndsAt: string;
}

export type PreviewInvoice = {
  prorationAmount: number | null;
  discountAmount: number | null;
  unusedTimeAmount: number | null;
  startingBalance: number | null;
  amountDue: number | null;
  prorationDate: number | null;
}

export type PromoCode = {
  id: string;
  name: string;
  amountOff: number;
  percentOff: number;
}

export type AccountUser = {
  id: number;
  email: string;
  user: User
  owner: boolean;
  admin: boolean;
  canCreateEvents: boolean;
  events: EventUser[];
  createdAt: string;
}

export type MessageTemplate = {
  id: string
  icon: string
  name: string
  features: string[]
  formType?: FormType
}

export type MessageTemplateGroup = {
  description: string;
  name: string;
  templates: MessageTemplate[];
  requiredPlan: SubscriptionType;
}

export type AccountConfiguration = {
    emailTemplates: { groups: MessageTemplateGroup[] };
    plans: Plan[];
    translations: Translations
}

export enum FormType {
  Registration = "Registration",
  Ticket = "Ticket",
  RSVP = "RSVP",
  Waitlist = "Waitlist",
  Survey = "Survey",
}

export enum TextMessageType {
  Invitation = "Invite Text",
  Confirmation = "Confirmation Text",
  General = "General Text",
}

export type BuilderBlockWithBlockId = {
  block: BuilderBlock,
  blockId: number,
}

export type Form = {
  externalId: string
  name: string
  private: boolean
  price: string
  currency: string
  submissionsEndAt: string
  limitPerSubmission: number
  disabled: boolean
  variablePrice: boolean
  grossSales: string
  netSales: string
  type: FormType
  submissionLineItemsCount: number
  quantity?: number
  description?: string
  formData: {
    blocks: BuilderBlock[]
  }
  profilePhoneEnabled: boolean
  profilePhoneRequired: boolean
  profileCompanyEnabled: boolean
  profileCompanyRequired: boolean
  profileAddressEnabled: boolean
  profileAddressRequired: boolean
  profileCityEnabled: boolean
  profileCityRequired: boolean
  profileCountryEnabled: boolean
  profileCountryRequired: boolean
  profilePostalCodeEnabled: boolean
  profilePostalCodeRequired: boolean
  profileStateEnabled: boolean
  profileStateRequired: boolean
  profileTitleEnabled: boolean
  profileTitleRequired: boolean
  translations: Translations
  profileNameEnabled: boolean
  profileEmailEnabled: boolean
  plusOnesEnabled: boolean
  maxPlusOnes: number
  submissionMessage: string | null
}

export type PaginationMeta = {
  currentPage: number;
  nextPage: number;
  prevPage: number;
  totalCount: number;
  totalPages: number;
}

export type FormFormValues = {
  private: Boolean | StringBoolean;
  limitPerSubmission?: number;
  disabled?: Boolean | StringBoolean;
  name?: string;
  submissionsEndAt?: string;
  type: FormType;
  description?: string;
  quantity?: number;
}

export type Submission = {
  createdAt: string
  currency: string
  externalId: string
  price: string
  submitterEmail: string
  submitterName: string
  lineItems: SubmissionLineItem[]
  paymentMethod: string
  paymentDetails: string
}

export type SubmissionGuest = {
  companyName?: string
  companyTitle?: string
  email: string
  firstName: string
  id: string
  lastName: string
  name: string
  phone?: string
  photoUrl: string
  qrCodeUrl: string
  status: GuestStatus
  address: {
    city?: string
    country?: string
    line1?: string
    line2?: string
    state?: string
    zip?: string
  }
}

export type SubmissionForm = {
  name: string
  type: FormType
  formData: { blocks: BuilderBlock[]}
  profileAddressEnabled: boolean
  profileAddressRequired: boolean
  profileCityEnabled: boolean
  profileCityRequired: boolean
  profileCompanyEnabled: boolean
  profileCompanyRequired: boolean
  profileCountryEnabled: boolean
  profileCountryRequired: boolean
  profilePhoneEnabled: boolean
  profilePhoneRequired: boolean
  profilePostalCodeEnabled: boolean
  profilePostalCodeRequired: boolean
  profileStateEnabled: boolean
  profileStateRequired: boolean
  profileTitleEnabled: boolean
  profileTitleRequired: boolean
  profileNameEnabled: boolean
  profileEmailEnabled: boolean
  profileZipEnabled: boolean
}

export type SubmissionLineItem = {
  currency: string
  externalId: string
  form: SubmissionForm,
  guest: SubmissionGuest,
  name: string,
  price: string,
  responses: { [key: string]: string | [] }
  createdAt: string
  updatedAt: string
  plusOnes: { id: string; name: string; email: string }[]
  formSubmission: {
    eventId: string,
    externalId: string,
    paymentDetails: string,
    paymentMethod: string,
  }
}

export enum FixedBlockType {
  GuestName,
  Registration,
  Payment,
  PlusOnes,
  FormSubmission,
}

export type RegistrationField = {
  name: string,
  enabled: boolean,
  required: boolean,
  fieldName: string,
  readOnly?: boolean,
  select?: boolean,
}

export type RegistrationFields = {
  personal: RegistrationField[],
  address: RegistrationField[],
}

export enum FormsSubpath {
  Forms = "forms",
  GuestPortal = "guest-portal",
  Header = "header",
  Colors = "colors",
  Terminology = "terminology",
  EmailResponders = "email-responders",
}

export enum FormsAnchor {
  GuestConfirmationSettings = "guest-confirmation-settings",
}

export enum SubmissionsSubpath {
  Questions = "questions",
}

export enum FormStatus {
  Published,
  Ended,
  Disabled,
  GatewayNotConnected,
  SoldOut,
}

export type TabButton<T> = {
  id: number,
  title: string,
  iconName: string
  subpath?: T,
}

export type FormFormType = {
  name: string;
  type: FormType;
  icon: string;
  component: ReactNode
};

export enum ButtonBlockAction {
  Form = "form",
  Website = "website",
  Other = "other",
}

export enum FormSubmissionSortOption {
  CreatedAtAsc = "created_at_asc",
  CreatedAtDesc = "created_at_desc",
  GuestNameAsc = "guest_name_asc",
  GuestNameDesc = "guest_name_desc",
  PriceAsc = "price_asc",
  PriceDesc = "price_desc",
}

export type RadioOption = {
  label: string | ReactNode
  value: string
}

export enum FormSortOption {
  CreatedAtAsc = "createdAtAsc",
  CreatedAtDesc = "createdAtDesc",
  Name = "name",
  Type = "type",
  Visibility = "visibility",
}

export enum DomainStatusValue {
  LegacyAuthenticated = "legacy authenticated",
  Authenticated = "authenticated",
  Pending = "pending",
  NotAuthenticated = "not authenticated",
}

export enum ThemeColor {
  Default = "default",
  Neutral = "neutral",
  Lapis = "lapis",
  Flix = "flix",
  Onyx = "onyx",
  Custom = "custom",
}

type SubmissionResponseObject = {
  url: string
  file: string
  type: string
};

export type SubmissionResponseType = string | SubmissionResponseObject | string[]

export type TextMessageDeliveryCounts = {
  delivered: number
  deliveryUnknown: number
  failed: number
  queued: number
  sent: number
  undelivered: number
}

export type TextMessage = {
  externalId: string
  name: string
  body: string
  recipients: string[]
  guestCount: number
  sentAt: string | null
  createdAt: string
  updatedAt: string
  deliveryCounts: TextMessageDeliveryCounts
}

export type TextMessageTest = {
  to: string
  body: string
}

export type MergeTagReplacement = {
  mergeTag: string;
  value: string;
};

export type FormsTerminologyModalProps = {
  title: string
  event: DioboxEvent,
  onClose: () => void
  onSubmit: (values: any) => Promise<void>
  defaultTranslations: Translations
}

export type TextMessageReport = {
  guest: {
    id: string;
    name: string;
  }
  data: {
    to: string;
    sid: string;
    body: string;
    from: string;
    status: string;
  }
  createdAt: string
}

export type EmailReportRecipient = Transaction & {
  eventTitle: string
  personId: string
  emailId: number
  clicked: number
  guest: {
    id: string
    status: number
    confirmedTime: string | null
    firstName: string
    lastName: string
    photoUrl: string
  }
}

export type URLSearchParam = {
  type: string
  value: string
}
