import React from "react"
import { Control } from "react-hook-form"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { Box } from "@chakra-ui/react"
import BasicInputs from "./BasicInputs"
import FormDetailHeader from "./FormDetailHeader"

type Props = {
  control: Control<object, any>
  showUpgradeAlert: boolean
  name: string
}

const Waitlist = ({ control, showUpgradeAlert, name }: Props) => (
  <Box pb={4}>
    <FormDetailHeader
      name={name}
      description="Requires prospective guests to apply for the event and join a waiting list. You can then qualify and either confirm or decline their registration."
    />
    {showUpgradeAlert && <UpgradeAlert noBorder mb={8} text="Requires Corporate Subscription" />}
    <BasicInputs control={control} showVisibilityInput showQuantityInput showLimitPerSubmission />
  </Box>
)

export default Waitlist
