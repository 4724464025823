import React from "react"
import { Stack, Text } from "@chakra-ui/react"
import Link from "components/elements/Link"
import SegmentTitle from "./SegmentTitle"

const InfoSegment = () => (
  <>
    <Stack spacing={8}>
      <Text>
        By activating your Lead Retrieval license, you can easily capture contact information
        and generate leads at this event—simply by scanning guest QR codes with a dedicated
        app right from your own mobile device.
      </Text>
      <Text>
        To learn more, please refer to the <Link isExternal href="https://help.d.io/en/articles/10312711-download-lead-retrieval-apps">Lead Retrieval Help</Link>.
      </Text>
    </Stack>
    <SegmentTitle leftText="Account" />
  </>
)

export default InfoSegment
