import React from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import * as Yup from "yup"
import { EMAIL_VALIDATION_REGEX } from "utilities/constants"
import { Stack, Text } from "@chakra-ui/react"
import TextField from "components/ReactHookForm/TextField"

const schema = Yup.object().shape({
  email: Yup.string().matches(EMAIL_VALIDATION_REGEX, "Invalid email format").required(),
})

export enum Mode {
  InviteExhibitor,
  AssignLicense
}

type Props = {
  onHide: () => void
  mode: Mode
}

const InviteExhibitorOrAssignLicense = ({ onHide, mode }: Props) => {
  let title
  let body
  switch (mode) {
    case Mode.InviteExhibitor: {
      title = "Invite Exhibitor to Purchase License"
      body = (
        <Text>Send an email to your exhibitor, allowing them to
          create an account and easily purchase a Lead
          Retrieval app license.
        </Text>
      )
      break
    }
    case Mode.AssignLicense: {
      title = "Assign License to Exhibitor"
      body = (
        <>
          <Text fontSize="2xl" fontWeight="semibold" textAlign="center">Remaining Licenses: 47</Text>
          <Text>Assign a license to your exhibitor, enabling them to
            create a Lead Retrieval account.
          </Text>
        </>
      )
      break
    }
    default: {
      break
    }
  }

  return (
    <ModalForm
      initialValues={{ email: "" }}
      validationSchema={schema}
      onSubmit={onHide}
      onClose={onHide}
      title={title}
      submitText="Send Invite"
    >
      {({ control }) => (
        <Stack spacing={6}>
          {body}
          <TextField
            vertical
            label="Exhibitor Email"
            name="email"
            control={control}
            variant="outline"
            placeholder="Enter email address"
          />
        </Stack>
      )}
    </ModalForm>
  )
}

export default InviteExhibitorOrAssignLicense
