import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { Redirect, Route, Switch } from "react-router-dom"
import { User } from "sharedTypes"
import {
  confirmEmailPath,
  forgotPasswordPath,
  leadRetrievalRegisterPath,
  loginPath,
  registrationPath, resetPasswordPath,
} from "utilities/routes"
import Login from "pages/Unauthenticated/Login"
import ConfirmEmail from "pages/Unauthenticated/ConfirmEmail"
import DownloadApp from "pages/DownloadApp"
import Registration from "pages/Unauthenticated/Registration"
import ForgotPassword from "pages/Unauthenticated/ForgotPassword"
import ResetPassword from "pages/Unauthenticated/ResetPassword"
import LeadRetrievalRegisterWrapper
  from "pages/Unauthenticated/LeadRetrieval/Register/LeadRetrievalRegisterWrapper"

type Props = {
  currentUser: User
  onClickForceDesktopView: () => void
  view: "mobile" | "desktop"
}

const Unauthenticated = ({ currentUser, onClickForceDesktopView, view }: Props) => (
  <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY!}>
    {view === "desktop" ? (
      <Switch>
        {mobileAndDesktopRoutes()}
        <Route path={loginPath()} exact>
          <Login />
        </Route>
        <Route path={confirmEmailPath()} exact>
          <ConfirmEmail currentUser={currentUser} />
        </Route>
        <Route>
          <Redirect to={loginPath()} />
        </Route>
      </Switch>
    ) : (
      <Switch>
        {mobileAndDesktopRoutes()}
        <Route>
          <DownloadApp onClickForceDesktopView={onClickForceDesktopView} />
        </Route>
      </Switch>
    )}
  </GoogleReCaptchaProvider>
)

const mobileAndDesktopRoutes = () => (
  [
    <Route key="registration-route" path={registrationPath()}>
      <Registration />
    </Route>,
    <Route key="forgot-password-route" path={forgotPasswordPath()} exact>
      <ForgotPassword />
    </Route>,
    <Route key="reset-password-route" path={resetPasswordPath()} exact>
      <ResetPassword />
    </Route>,
    <Route key={leadRetrievalRegisterPath()} path={`${leadRetrievalRegisterPath()}/:eventId/:subpath?`} exact>
      <LeadRetrievalRegisterWrapper />
    </Route>,

  ]
)

export default Unauthenticated
