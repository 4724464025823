import React from "react"
import { Control } from "react-hook-form"
import { Box } from "@chakra-ui/react"
import FormDetailHeader from "../FormDetailHeader"
import BasicInputs from "../BasicInputs"

type Props = {
  control: Control<object, any>
  name: string
  currency: string
}

const Ticket = ({ control, name, currency }: Props) => (
  <Box pb={6}>
    <FormDetailHeader
      name={name}
      description="Enables ticket sales with a streamlined checkout experience for guests or through the point-of-sale integration on the Diobox mobile app."
    />
    <BasicInputs
      control={control}
      showVisibilityInput
      showQuantityInput
      showLimitPerSubmission
      showPrice
      currency={currency}
    />
  </Box>
)

export default Ticket
