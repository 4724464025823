import React from "react"
import {
  Flex, Box, Text,
} from "@chakra-ui/react"
import Avatar from "components/elements/Avatar"
// import Popover from "components/dialogs/Popover"
// import Icon from "components/elements/Icon"
// import PopoverBody from "./PopoverBody"

type Props = {
  exhibitor: {
    name: string
    email: string
    photoUrl: string
  }
}

const ExhibitorListItem = ({
  exhibitor: {
    email, photoUrl, name,
  },
}: Props) => (
  <Flex justifyContent="space-between" alignItems="center" px={6}>
    <Flex alignItems="center" py={4} gap={2}>
      <Avatar name={name} src={photoUrl} />
      <Box>
        <Flex alignItems="center" gap={3}>
          <Text fontSize="xl">{name}</Text>
        </Flex>
        <Text>{email}</Text>
      </Box>
    </Flex>
    {/* <Popover
      contentWidth={160}
      trigger={<Icon icon="more" size={6} color="blue.500" />}
      body={<PopoverBody />}
    /> */}
  </Flex>
)

export default ExhibitorListItem
