import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import Avatar from "components/elements/Avatar"
import Badge from "./Badge"

type Props = {
  email: string
  name?: string
  photoUrl?: string
  current?: boolean
  unconfirmed?: boolean
  invited?: boolean
  w?: number | string
}

const UserWithAvatar = ({
  name, email, photoUrl, current, unconfirmed, invited, w,
}: Props) => (
  <Flex w={w || "50%"} align="center">
    <Avatar
      mr={3}
      src={photoUrl}
      name={name || email}
    />
    <Box lineHeight={6}>
      {name && (
      <Flex align="center" wrap="wrap">
        <Text fontSize="2xl" fontWeight="normal" mr={2}>{name}</Text>
        {current && <Badge color="gray.600" borderColor="gray.500">You</Badge>}
      </Flex>
      )}
      <Flex align="center" wrap="wrap">
        <Text fontSize="md" mr={2} noOfLines={1} maxW={260}>{email}</Text>
        {invited && <Badge color="orange.400">Invited</Badge>}
        {unconfirmed && <Badge color="orange.400">Unconfirmed Email</Badge>}
      </Flex>
    </Box>
  </Flex>
)

export default UserWithAvatar
