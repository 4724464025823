import React from "react"
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Box,
  TypographyProps,
  CloseButton,
  Text,
} from "@chakra-ui/react"
import {
  Control, FieldValues, useController,
} from "react-hook-form"

type Props<T extends FieldValues> = {
  id?: string
  name: string
  control: Control<T, object>
  size?: TypographyProps["fontSize"]
  labelSize?: TypographyProps["fontSize"]
  type?: string
  variant?: "outline" | "filled" | "flushed" | "unstyled"
  onChange?: (event) => void
  onFocus?: (event) => void
  label?: string
  placeholder?: string
  required?: boolean
  autoComplete?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  onBlur?: (event) => void
  leftElement?: string
  rightElement?: string
  validate?: () => Promise<any>
  mb?: string|number
  color?: string
  vertical?: boolean
  readonly?: boolean
  h?: number
  showDeleteButton?: boolean
  onPaste?: (event: React.ClipboardEvent) => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  errorMessage?: string
  showAsterisk?: boolean
}

const TextField = <T extends FieldValues, >({
  id,
  name,
  control,
  size = "md",
  labelSize = "md",
  type = "text",
  variant = "flushed",
  onChange = () => {},
  onFocus = () => {},
  label,
  placeholder,
  autoComplete = "disabled",
  required,
  disabled,
  error,
  helperText,
  onBlur,
  leftElement,
  rightElement,
  validate,
  mb = 4,
  color = "",
  vertical,
  readonly,
  h = 10,
  showDeleteButton = false,
  onPaste,
  onKeyDown,
  errorMessage,
  showAsterisk,
}: Props<T>) => {
  const { field } = useController<any>({ name, control, rules: { validate } })

  return (
    <FormControl
      display="flex"
      alignItems="flex-start"
      flexDirection={vertical ? "column" : "row"}
      isDisabled={disabled}
      mb={mb}
      isInvalid={error}
    >
      {label && (
        <FormLabel
          fontWeight="bold"
          w={vertical ? "full" : 1 / 4}
          fontSize={labelSize}
          mt={2}
        >
          {label}
          {showAsterisk && <Text as="span" color="red"> *</Text>}
        </FormLabel>
      )}
      <Box w="100%">
        <InputGroup>
          {leftElement && <InputLeftElement zIndex={1}>{leftElement}</InputLeftElement>}
          <Input
            id={id}
            {...field}
            variant={variant}
            type={type}
            autoComplete={autoComplete}
            placeholder={placeholder}
            isRequired={required}
            onChange={(event) => {
              onChange(event)
              field.onChange(event)
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            bg="white"
            fontSize={size}
            isReadOnly={readonly}
            color={color}
            _readOnly={{ color: "gray.500" }}
            h={h}
            onPaste={onPaste}
            onKeyDown={onKeyDown}
          />
          {rightElement && <InputRightElement w="auto">{rightElement}</InputRightElement>}
          {(showDeleteButton && field.value) && <InputRightElement w="auto"><CloseButton onClick={() => { field.onChange("") }} /></InputRightElement>}
        </InputGroup>
        {errorMessage && <Text fontSize="sm" color="red">{errorMessage}</Text>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Box>
    </FormControl>
  )
}

export default TextField
