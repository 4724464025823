import React from "react"
import EventResourcesView from "components/layout/EventResourcesView"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import * as api from "api/forms"
import * as Yup from "yup"
import { refetchForms, updateForm } from "context/actions"
import { useQueryClient } from "react-query"
import {
  DioboxEvent,
  Form, FormFormType, FormFormValues, FormType,
} from "sharedTypes"
import useCurrentAccount from "services/useCurrentAccount"
import { Boolean } from "utilities/enums"
import { useHistory } from "react-router"
import { eventFormPath } from "utilities/routes"
import { stripOutQuestionsFormBlockData } from "components/Builder/helpers"
import { formIconAndType, getEnabledFormTypesBySubscriptionLevel } from "utilities/forms"
import useGenerateName from "services/useGenerateName"
import useFeatureFlag from "services/featureFlags"
import { stripHtmlTags } from "utilities/strings"
import Registration from "./Registration"
import Ticket from "./Ticket/Ticket"
import RSVP from "./RSVP"
import Waitlist from "./Waitlist"
import ListItem from "./ListItem"
import Survey from "./Survey"

type Props = {
  event: DioboxEvent
  onClose: () => void
  onSuccess?: (formData: Form) => void
  form?: Form
}

export const formFormInitialValues = {
  type: FormType.Registration,
  disabled: false,
  private: false,
  limitPerSubmission: 10,
}

const FormForm = ({
  event, onClose, onSuccess, form: currentForm,
}: Props) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { subscription } = useCurrentAccount()
  const { id: eventId, currency } = event
  const { generateFormName } = useGenerateName(eventId)
  const { surveyForms: surveyFormsEnabled } = useFeatureFlag()

  const handleSuccess = (formData: Form) => {
    onSuccess?.(formData)
    refetchForms(queryClient, eventId)
    onClose()
  }

  const handleSubmit = (values: Form) => {
    if (currentForm) {
      const formWithoutQuestionsFormBlockData = stripOutQuestionsFormBlockData(values)
      const submissionMessageIsEmpty = values.submissionMessage === null || stripHtmlTags(values.submissionMessage) === ""

      return api.patchForm(eventId,
        currentForm.externalId,
        {
          ...formWithoutQuestionsFormBlockData,
          submissionMessage: submissionMessageIsEmpty ? "" : values.submissionMessage,
        })
        .then(({ data: updatedForm }) => {
          handleSuccess(updatedForm)
          updateForm(queryClient, currentForm.externalId, updatedForm)
        })
    }

    return api.postForm(eventId, values).then(({ data: { externalId }, data }) => {
      handleSuccess(data)
      history.push(eventFormPath(eventId, externalId))
    })
  }

  const enabledFormTypes = getEnabledFormTypesBySubscriptionLevel(subscription)

  const validationSchema = Yup.object().shape({
    disabled: Yup.string().oneOf([Boolean.True, Boolean.False]),
    name: Yup.string().required(),
    description: Yup.string().nullable(),
    private: Yup.string().oneOf([Boolean.True, Boolean.False]),
    submissionsEndAt: Yup.date().nullable(),
    type: Yup.string().oneOf(enabledFormTypes),
    quantity: Yup.lazy((value) => (value === "" ? Yup.string() : Yup.number().integer().min(0).nullable())),
    limitPerSubmission: Yup.number().min(1).max(10).nullable(),
    price: Yup.string().when(["variablePrice", "type"], {
      is: (variablePrice, type) => variablePrice !== Boolean.True && type === FormType.Ticket,
      then: (schema) => schema.notOneOf(["0.00"]).required(),
      otherwise: (schema) => schema.nullable(),
    }),
    variablePrice: Yup.string().oneOf([Boolean.True, Boolean.False]),
    submissionMessage: Yup.string().nullable(),
  })

  let initialValues: FormFormValues = {
    ...formFormInitialValues,
    name: generateFormName(FormType.Registration),
  }

  if (currentForm) {
    initialValues = currentForm
  }

  return (
    <ModalForm
      title={`${currentForm ? "Edit" : "New"} Form`}
      size="4xl"
      h="80%"
      noPadding
      onClose={onClose}
      onSubmit={handleSubmit}
      submitText={currentForm ? "Save" : "Create"}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({
        control, watch, reset,
      }) => {
        const values: FormFormValues = watch()

        const registationIconAndType = formIconAndType(FormType.Registration)
        const ticketIconAndType = formIconAndType(FormType.Ticket)
        const rsvpIconAndType = formIconAndType(FormType.RSVP)
        const waitlistIconAndType = formIconAndType(FormType.Waitlist)
        const surveyIconAndType = formIconAndType(FormType.Survey)

        const formTypes: FormFormType[] = [
          {
            name: registationIconAndType.type,
            icon: registationIconAndType.icon,
            type: FormType.Registration,
            component: <Registration control={control} name={registationIconAndType.type} />,
          },
          {
            name: ticketIconAndType.type,
            icon: ticketIconAndType.icon,
            type: FormType.Ticket,
            component: <Ticket
              control={control}
              name={ticketIconAndType.type}
              currency={currency}
            />,
          },
          {
            name: rsvpIconAndType.type,
            icon: rsvpIconAndType.icon,
            type: FormType.RSVP,
            component: <RSVP
              control={control}
              showUpgradeAlert={!enabledFormTypes.includes(FormType.RSVP)}
              name={rsvpIconAndType.type}
            />,
          },
          {
            name: waitlistIconAndType.type,
            icon: waitlistIconAndType.icon,
            type: FormType.Waitlist,
            component: <Waitlist
              control={control}
              showUpgradeAlert={!enabledFormTypes.includes(FormType.Waitlist)}
              name={waitlistIconAndType.type}
            />,
          },

        ]

        if (surveyFormsEnabled) {
          formTypes.push({
            name: surveyIconAndType.type,
            icon: surveyIconAndType.icon,
            type: FormType.Survey,
            component: <Survey
              control={control}
              showUpgradeAlert={!enabledFormTypes.includes(FormType.Waitlist)}
              name={surveyIconAndType.type}
            />,
          })
        }

        const selectedType = formTypes.find(
          (formType) => formType.type === values.type,
        )

        return (
          <EventResourcesView
            listWidth="sm"
            showBorder={false}
            listItems={formTypes.map(
              (f) => (
                <ListItem
                  key={f.type}
                  form={f}
                  initialValues={initialValues}
                  values={values}
                  reset={reset}
                  disabled={currentForm && selectedType?.type !== f.type}
                  eventId={eventId}
                />
              ),
            )}
            selectedItem={selectedType?.component}

          />
        )
      }}
    </ModalForm>
  )
}

export default FormForm
