import {
  Box, Flex, Heading, Link as ChakraLink,
} from "@chakra-ui/react"
import React, { ReactNode } from "react"
import Icon from "components/elements/Icon"
import { signOut } from "services/authentication"
import { useIntercom } from "react-use-intercom"
import { useQueryClient } from "react-query"
import Button from "../Buttons/Button"
import PageWithDioboxLogo from "./PageWithDioboxLogo"

type Props = {
  title: string
  children: ReactNode
  footer?: ReactNode
  onClose?: () => void
  onClickSupportLogin?: () => void
}

const AccountError = ({
  title, children, footer, onClose, onClickSupportLogin,
}: Props) => {
  const { shutdown: shutdownIntercom } = useIntercom()
  const queryClient = useQueryClient()

  return (
    <PageWithDioboxLogo onClose={onClose}>
      <Box>
        <Box borderRadius="lg" bg="white" borderWidth={2} maxW={500} textAlign="center" p={8} mx={4}>
          <Icon size={24} icon="alert" />
          <Heading fontSize="2xl" fontWeight="semibold">{title}</Heading>
          {children}
        </Box>
        {onClickSupportLogin && (
        <Flex
          borderWidth={2}
          bg="white"
          borderRadius="md"
          mt={10}
          align="center"
          justify="center"
          direction="column"
          gap={8}
          p={8}
        >
          <Heading fontSize="2xl" fontWeight="semibold" color="pink.600">Diobox Support
            Agent
          </Heading>
          <Button
            onClick={onClickSupportLogin}
            size="md"
          >Proceed to Account
          </Button>
        </Flex>
        )}
        <Flex h={10} mt={6} color="blue.500" justify="center">
          {footer} {footer && <Box mx={5}>•</Box>}
          <ChakraLink onClick={() => signOut(queryClient, shutdownIntercom)}>
            Sign Out
          </ChakraLink>
        </Flex>
      </Box>
    </PageWithDioboxLogo>
  )
}

export default AccountError
