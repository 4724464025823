import React from "react"
import { Control } from "react-hook-form"
import { Box } from "@chakra-ui/react"
import BasicInputs from "./BasicInputs"
import FormDetailHeader from "./FormDetailHeader"

type Props = {
  control: Control<object, any>
  name: string
}

const Registration = ({ control, name }: Props) => (
  <Box pb={4}>
    <FormDetailHeader
      name={name}
      description="Allows anyone to register for your event."
    />
    <BasicInputs control={control} showVisibilityInput showQuantityInput showLimitPerSubmission />
  </Box>
)

export default Registration
