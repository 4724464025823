import React from "react"
import { Stack, Text } from "@chakra-ui/react"
import Card from "components/layout/Card"

const NoLeadRetrievalUserFound = () => (
  <Card>
    <Stack alignItems="center" spacing={7} py={6}>
      <Text fontWeight="semibold">No exhibitors found!</Text>
      <Text>Exhibitors will be displayed here once their licenses are activated.</Text>
    </Stack>
  </Card>
)

export default NoLeadRetrievalUserFound
