import React from "react"
import {
  Flex, Heading, Stack, Text,
} from "@chakra-ui/react"
import DocsLink from "components/DocsLink"
import PageWithDioboxLogo from "components/layout/PageWithDioboxLogo"
import AppDownloadLinks from "components/AppDownloadLinks"
import { LEAD_RETRIEVAL_APP_APPSTORE_LINK, LEAD_RETRIEVAL_APP_PLAYSTORE_LINK } from "utilities/externalLinks"
import { signOut } from "services/authentication"
import { useIntercom } from "react-use-intercom"
import { useQueryClient } from "react-query"
import Link from "components/elements/Link"

const LeadRetrievalAppInfo = () => {
  const { shutdown: shutdownIntercom } = useIntercom()
  const queryClient = useQueryClient()

  return (
    <PageWithDioboxLogo>
      <Flex flexDirection="column" alignItems="center" gap={4}>
        <Stack spacing={8} borderRadius="lg" bg="white" borderWidth={2} maxW={500} p={8} mx={4}>
          <Heading fontSize="2xl" fontWeight="semibold" textAlign="center">Lead Retrieval App</Heading>
          <Text>You’ve been invited to use the Lead Retrieval app by
            the event organizer.
          </Text>
          <Text>The Diobox Lead Retrieval app enables you to scan
            guest QR codes directly from your phone, making it
            easy to capture contact information, add personalized
            notes, and seamlessly export your leads.
          </Text>
          <Text>To start scanning leads, download the apps.</Text>
          <AppDownloadLinks
            iOSAppURL={LEAD_RETRIEVAL_APP_APPSTORE_LINK}
            AndroidAppURL={LEAD_RETRIEVAL_APP_PLAYSTORE_LINK}
          />
        </Stack>
        <DocsLink url="https://help.d.io">
          Learn more about Lead Retrieval
        </DocsLink>
        <Link onClick={() => signOut(queryClient, shutdownIntercom)}>
          Sign Out
        </Link>
      </Flex>
    </PageWithDioboxLogo>
  )
}

export default LeadRetrievalAppInfo
