import React from "react"
import { Box, Stack, Text } from "@chakra-ui/react"
import { LeadRetrievalUser } from "sharedTypes"
import InfoSegment from "../InfoSegment"

type Props = {
  leadRetrievalUser: LeadRetrievalUser
}

const AlreadyHaveLicenseScreen = ({ leadRetrievalUser }: Props) => (
  <>
    <InfoSegment />
    <Stack spacing={5} textAlign="center">
      <Box mt={2} mb={6}>
        <Text>The email address <b>{leadRetrievalUser.email}</b> {" "}
          is already associated with this event.
        </Text>
        <Text>Please return to the previous page and use a different email address.</Text>
      </Box>
    </Stack>
  </>
)

export default AlreadyHaveLicenseScreen
