import { PaymentElement } from "@stripe/react-stripe-js"
import React from "react"
import { FormControl } from "@chakra-ui/react"
import { Control, FieldValues, useController } from "react-hook-form"

type Props<T extends FieldValues> = {
  name: string;
  control: Control<T, object>
}

const PaymentField = <T extends FieldValues, >({ name, control }: Props<T>) => {
  const { field } = useController<any>({ name, control })

  const handleChange = ({ complete }) => {
    if (complete) {
      field.onChange(complete)
    }
  }

  return (
    <FormControl>
      <PaymentElement
        onChange={(event) => handleChange(event)}
      />
    </FormControl>
  )
}

export default PaymentField
