import React from "react"
import { Flex, Link, Image } from "@chakra-ui/react"
import downloadOnTheAppStore from "assets/images/download-from-app-store.png"
import downloadFromGooglePlay from "assets/images/download-from-google-play.png"

type Props = {
  iOSAppURL: string
  AndroidAppURL: string
}

const AppDownloadLinks = ({ iOSAppURL, AndroidAppURL }:Props) => (
  <Flex gap={4} justifyContent="center" alignItems="center">
    <Link href={iOSAppURL} isExternal>
      <Image w={36} src={downloadOnTheAppStore} display="inline-block" />
    </Link>
    <Link href={AndroidAppURL} isExternal>
      <Image w={36} src={downloadFromGooglePlay} display="inline-block" />
    </Link>
  </Flex>
)

export default AppDownloadLinks
