import { useCurrentEvent } from "queries/events"
import useCurrentAccount from "./useCurrentAccount"

export enum EventUserRole {
  EventAdmin = "event_administrator",
  GuestManager = "guest_manager",
  DoorOperator = "door_operator",
  DoorStaff = "door_staff",
  WebsiteAdmin = "website_admin",
  NoAccess = "no_access",
  LeadRetrieval = "lead_retrieval",
}

const usePermissions = () => {
  const { data: { currentUserRole } } = useCurrentEvent()
  const { admin } = useCurrentAccount()

  const permissions = {
    canViewGuests: true,
    canViewGuestDetails: true,
    canEditGuests: true,
    canViewEmails: true,
    canViewSeating: true,
    canEditSeating: true,
    canViewForm: true,
    canViewWebsite: true,
    canViewSettings: true,
    canViewSubmissions: true,
    canViewTexting: true,
  }

  if (admin) {
    return permissions
  }
  /* eslint-disable max-len */

  return {
    canViewGuests: [EventUserRole.EventAdmin, EventUserRole.GuestManager, EventUserRole.DoorOperator, EventUserRole.DoorStaff].includes(currentUserRole),
    canViewGuestDetails: [EventUserRole.EventAdmin, EventUserRole.GuestManager, EventUserRole.DoorOperator].includes(currentUserRole),
    canEditGuests: [EventUserRole.EventAdmin, EventUserRole.GuestManager].includes(currentUserRole),
    canViewEmails: [EventUserRole.EventAdmin, EventUserRole.GuestManager].includes(currentUserRole),
    canViewSeating: [EventUserRole.EventAdmin, EventUserRole.GuestManager, EventUserRole.DoorOperator].includes(currentUserRole),
    canEditSeating: [EventUserRole.EventAdmin, EventUserRole.GuestManager].includes(currentUserRole),
    canViewForm: [EventUserRole.EventAdmin, EventUserRole.WebsiteAdmin].includes(currentUserRole),
    canViewWebsite: [EventUserRole.EventAdmin, EventUserRole.WebsiteAdmin].includes(currentUserRole),
    canViewSettings: [EventUserRole.EventAdmin].includes(currentUserRole),
    canViewSubmissions: [EventUserRole.EventAdmin, EventUserRole.GuestManager].includes(currentUserRole),
    canViewTexting: [EventUserRole.EventAdmin].includes(currentUserRole),
  }
  /* eslint-enable max-len */
}

export default usePermissions
