import React from "react"
import TextField from "components/ReactHookForm/TextField"
import { Control, FieldValues } from "react-hook-form"
import { Box, Stack, Text } from "@chakra-ui/react"
import { LeadRetrievalUser } from "sharedTypes"
import PaymentField from "components/ReactHookForm/PaymentField"
import InfoSegment from "../InfoSegment"
import SegmentTitle from "../SegmentTitle"

type Props<T extends FieldValues> = {
  control: Control<T, object>
  leadRetrievalUser: LeadRetrievalUser
  leadRetrievalLicenseFee: string
}

const BuyLicenseScreen = <T extends FieldValues, >({
  control,
  leadRetrievalUser,
  leadRetrievalLicenseFee,
}: Props<T>) => (
  <>
    <InfoSegment />
    <Stack>
      <Stack spacing={4} mb={4}>
        <Text>
          You already have a valid account registered with this email address:
        </Text>
        <Text fontWeight="semibold">
          {leadRetrievalUser.email}
        </Text>
        <Text>
          To access the event in the Lead Retrieval App, please complete the license fee
          payment. Once the payment is confirmed, the event will automatically be available
          in yout app.
        </Text>
      </Stack>
      <TextField
        control={control}
        name="name"
        vertical
        variant="outline"
        label="Exhibitor Name"
        placeholder="ACME Corp"
        helperText="In most cases, this should be the name of your company or team. You can change this later."
        showAsterisk
      />
    </Stack>
    <SegmentTitle leftText="License Fee" rightText={`$${Number(leadRetrievalLicenseFee).toFixed(2)}`} />
    <Box borderWidth={1} borderRadius="md" p={3}>
      <PaymentField name="paymentCompleted" control={control} />
    </Box>
  </>
  )

export default BuyLicenseScreen
