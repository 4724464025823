import React from "react"
import { Stack, Card, Image } from "@chakra-ui/react"
import LRAppPreview from "assets/images/lead-retrieval-app-preview.png"
import UpgradeAlert from "components/elements/UpgradeAlert"

const LeadRetrievalUpgradeAlert = () => (
  <Stack alignItems="center" spacing={7}>
    <Card px={6} py={8}>
      <Image src={LRAppPreview} />
    </Card>
    <UpgradeAlert text="This feature requires an Enterprise Subscription." />
  </Stack>
)

export default LeadRetrievalUpgradeAlert
