import React from "react"
import AuthenticationWrapper from "pages/Unauthenticated/AuthenticationWrapper"
import { VStack, HStack, Box } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { completeRegistrationPath, eventsPath, useQuery } from "utilities/routes"
import EmailField from "components/ReactHookForm/EmailField"
import TextField from "components/ReactHookForm/TextField"
import PasswordField from "components/ReactHookForm/PasswordField"
import SubmitButton from "components/Buttons/SubmitButton"
import { checkAuth, createUser } from "api/sessions"
import * as Yup from "yup"
import { storeAuthToken } from "services/authentication"
import { handleAuthResponse } from "components/SessionHydrater"
import { useIntercom } from "react-use-intercom"
import { useQueryClient } from "react-query"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import TOULinks from "components/TOULinks"
import { errorToast } from "../../utilities/toasts"

const Registration = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const history = useHistory()
  const { boot: bootIntercom, update: updateIntercom } = useIntercom()
  const queryClient = useQueryClient()

  const query = useQuery()
  const ref = query.get("ref")
  const plan = query.get("plan")
  const invited = !!query.get("invited")

  const onSubmit = async (params) => {
    if (!executeRecaptcha) {
      errorToast()

      return
    }
    let recaptchaToken
    try {
      recaptchaToken = await executeRecaptcha("register")
    } catch {
      errorToast()

      return
    }

    await createUser({ ...params, ref, recaptchaToken })
      .then(({ data }) => {
        storeAuthToken(data.authenticationToken)
        checkAuth().then(({ data: authData }) => {
          const redirectTo = invited ? eventsPath() : completeRegistrationPath(plan)
          history.replace(redirectTo)
          handleAuthResponse(authData, bootIntercom, queryClient)
          updateIntercom({ hideDefaultLauncher: true })
        }).catch(() => {})
      }).catch(() => {})
  }

  const RegistrationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required().min(6),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    accountName: Yup.string(),
  })

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    industry: "",
    accountName: invited ? "" : undefined,
    invited: !!invited,
  }

  const { control, handleSubmit, formState: { isValid, isSubmitting } } = useForm({
    resolver: yupResolver(RegistrationSchema),
    defaultValues: initialValues,
    mode: "onChange",
  })

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={6}>
        <EmailField name="email" control={control} placeholder="Work Email Address" />
        <HStack w="full" pb={4}>
          <TextField name="firstName" control={control} placeholder="First name" mb={0} />
          <TextField name="lastName" control={control} placeholder="Last name" mb={0} />
        </HStack>
        {!invited && (
        <TextField
          name="accountName"
          control={control}
          placeholder="Organization"
          helperText="Appears in menus and event headings"
        />
        )}
        <PasswordField
          name="password"
          control={control}
          placeholder="Password"
          helperText="Must contain at least 6 characters"
        />
        <Box pt={10}>
          <SubmitButton
            text="Sign Up Free"
            disabled={!isValid}
            submitting={isSubmitting}
          />
        </Box>
        <TOULinks />
      </VStack>
    </form>
  )

  return (
    <AuthenticationWrapper
      pageTitle="Welcome"
      body={form}
    />
  )
}

export default Registration
