import { Box } from "@chakra-ui/react"
import React from "react"

const PreventPasswordAutofill = () => (
  <Box h={0} overflow="hidden">
    <input type="email" name="noemail" />
    <input type="password" name="nopassword" />
  </Box>
)

export default PreventPasswordAutofill
