import { useQuery } from "react-query"
import {
  getEvent, getEvents,
  getPublicEvent,
} from "api/events"
import { matchPath, useLocation } from "react-router-dom"
import { isUUID } from "utilities/strings"
import { EventUserRole } from "services/permissions"
import { currentUserId } from "services/authentication"
import { DioboxEvent } from "../sharedTypes"

export const eventsCacheKey = (
  accountId: string, dateFilter: string|null, cloneId: string|null,
) => ["events", accountId, dateFilter, cloneId]
export const eventCacheKey = (eventId: string) => ["event", eventId]
const publicEventCacheKey = (eventId: string) => ["publicEventCacheKey", eventId]

export const useCurrentEvent = () => {
  const eventId = (matchPath(useLocation().pathname, "/events/:eventId")?.params as any)?.eventId

  const { isLoading, data: event } = useQuery(
    eventCacheKey(eventId), () => getEvent(eventId).then(({ data }) => {
      // eslint-disable-next-line no-param-reassign
      data.currentUserRole = (data.users || [])
        .find((eventUser) => eventUser.user?.id === currentUserId())?.role as EventUserRole

      return data
    }),
    { enabled: isUUID(eventId) },
  )

  return { isLoading, data: event || {} as DioboxEvent }
}

export const useEvents = (
  accountId: string, dateFilter?: string|null, cloneId?: string|null,
) => useQuery(
  eventsCacheKey(accountId, dateFilter || null, cloneId || null),
  () => getEvents(dateFilter || null).then(({ data }) => data),
)

export const usePublicEvent = (eventId: string) => useQuery(publicEventCacheKey(eventId),
  () => getPublicEvent(eventId).then(({ data }) => data))
