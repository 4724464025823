import { useHistory } from "react-router-dom"
import React, { useEffect, useRef } from "react"
import { useCurrentEvent } from "queries/events"
import useModal from "services/useModal"
import {
  Account, AccountUser, ModalName, User,
} from "sharedTypes"
import EnrichDisabled from "modals/EnrichDisabled"
import EventImage from "modals/EventImage"
import ExportGuests from "modals/ExportGuests"
import ExportInvitationList from "modals/ExportInvitationList"
import ImportGuests from "modals/ImportGuests"
import NewGuest from "modals/NewGuest"
import NewPlusOnes from "modals/NewPlusOnes"
import GuestListForm from "modals/GuestListForm"
import SegmentForm from "modals/SegmentForm"
import SendTestEmail from "modals/Emails/SendTestEmail"
import ScheduleSendEmail from "modals/Emails/ScheduleSendEmail"
import AccountChooser from "modals/SwitchAccount"
import TableForm from "modals/TableForm"
import UpgradePlan from "modals/UpgradePlan"
import WebPageURL from "modals/WebPageURL"
import AccountUserForm from "modals/AccountUserForm"
import SelectGuest from "modals/SelectGuest"
import ChangeStatus from "pages/EventShow/Guests/MultipleSelectActions/Action/ChangeStatus"
import FormForm from "modals/FormForm/FormForm"
import QuestionModal from "modals/QuestionModal"
import NewEmail from "modals/Emails/NewEmail"
import AddFormQuestion from "modals/FormForm/AddFormQuestion"
import EditMessaging from "modals/EditMessaging/EditMessaging"
import NewTextMessage from "modals/NewTextMessage"
import PreviewTextMessage from "modals/PreviewTextMessage/PreviewTextMessage"
import EditTextRecipients from "modals/EditTextRecipients"
import EditFieldModal from "modals/EditFieldModal"
import ExportTextReport from "modals/ExportTextReport"
import InviteExhibitorOrAssignLicense from "modals/InviteExhibitorOrAssignLicense"
import EventActivityForm from "../../modals/EventActivityForm"

type Props = {
  currentAccount: Account
  currentUser: User
  currentModal: { name: ModalName, params?: Record<string, any>}
  teamMembers: AccountUser[]
}

const Modals = ({
  currentAccount, currentUser, currentModal, teamMembers,
}: Props) => {
  const history = useHistory()
  const { data: currentEvent } = useCurrentEvent()
  const showModal = useModal()

  const onHide = () => showModal(null)

  const unblock = useRef(() => {
  })

  useEffect(() => {
    // @ts-ignore
    unblock.current = history.block((transition, action) => {
      if (action === "PUSH") {
        return true
      }

      onHide()

      return false
    })

    return () => {
      unblock.current()
      unblock.current = () => {
      }
    }
  }, [])

  switch (currentModal?.name) {
    case ModalName.EnrichDisabled:
      return <EnrichDisabled onHide={onHide} />
    case ModalName.EventImage:
      return (
        <EventImage
          eventId={currentEvent.id}
          coverImageUrl={currentEvent.website?.coverImageUrl}
          onHide={onHide}
        />
      )
    case ModalName.ExportGuests:
      return (
        <ExportGuests
          eventId={currentEvent.id}
          eventGroups={currentEvent.eventGroups}
          segments={currentEvent.segments}
          counts={currentEvent.counts}
          onHide={onHide}
          currentUser={currentUser}
        />
      )
    case ModalName.ExportInvitationList:
      return (
        <ExportInvitationList
          eventId={currentEvent.id}
          emailId={currentModal?.params?.id}
          emailAddress={currentUser.email}
          onHide={onHide}
        />
      )
    case ModalName.ExportTextReport:
      return (
        <ExportTextReport
          eventId={currentEvent.id}
          textMessageId={currentModal?.params?.id}
          emailAddress={currentUser.email}
          onHide={onHide}
        />
      )
    case ModalName.ImportGuests:
      return (
        <ImportGuests
          eventId={currentEvent.id}
          guestsCount={currentEvent.counts.guests}
          importLimit={currentEvent.nonTicketedGuestLimit}
          onClose={onHide}
        />
      )
    case ModalName.NewEmail:
      return (
        <NewEmail event={currentEvent} onClose={onHide} />
      )
    case ModalName.EditEmailName:
      return (
        <EditFieldModal
          onHide={onHide}
          title="Email Name"
          subtitle="This title is for internal use and won't be visible to your guests."
          onSubmit={currentModal.params?.onSubmit}
          fieldName="title"
          initialValues={currentModal.params?.initialValues}
        />
      )
    case ModalName.EditTextMessageName:
      return (
        <EditFieldModal
          onHide={onHide}
          title="Text Message Name"
          subtitle="This title is for internal use and won't be visible to your guests."
          onSubmit={currentModal.params?.onSubmit}
          fieldName="name"
          initialValues={currentModal.params?.initialValues}
        />
      )
    case ModalName.ScheduleSendEmail:
      return (
        <ScheduleSendEmail
          event={currentEvent}
          email={currentModal.params?.email}
          onHide={onHide}
          onSave={currentModal.params?.onSave}
        />
      )
    case ModalName.NewGuest:
      return (
        <NewGuest
          eventId={currentEvent.id}
          parentId={currentModal?.params?.parentId}
          onHide={onHide}
        />
      )
    case ModalName.NewPlusOnes:
      return (
        <NewPlusOnes
          eventId={currentEvent.id}
          guestId={currentModal?.params?.guestId}
          plusOnesCount={currentModal?.params?.plusOnesCount}
          guestName={currentModal?.params?.guestName}
          onHide={onHide}
        />
      )
    case ModalName.GuestListForm:
      return (
        <GuestListForm
          eventId={currentEvent.id}
          eventGroupId={currentModal?.params?.eventGroupId}
          eventGroups={currentEvent.eventGroups}
          users={currentEvent.users}
          teamMembers={teamMembers}
          onHide={onHide}
        />
      )
    case ModalName.SegmentForm:
      return (
        <SegmentForm
          eventId={currentEvent.id}
          segmentsEnabled={currentEvent.segmentsEnabled}
          segmentId={currentModal?.params?.segmentId}
          segments={currentEvent.segments}
          eventGroups={currentEvent.eventGroups}
          onHide={onHide}
        />
      )
    case ModalName.SendTestEmail:
      return (
        <SendTestEmail
          eventId={currentEvent.id}
          emailId={currentModal?.params?.id}
          emailAddress={currentUser.email}
          onHide={onHide}
        />
      )
    case ModalName.SwitchAccount:
      return (
        <AccountChooser
          currentUser={currentUser}
          accounts={currentUser.accounts}
          hide={onHide}
          newAccount={currentModal?.params?.newAccount}
        />
      )
    case ModalName.TableForm:
      return (
        <TableForm
          eventId={currentEvent.id}
          tableId={currentModal?.params?.id}
          tables={currentEvent.tables}
          onHide={onHide}
        />
      )
    case ModalName.Upgrade:
      return (
        <UpgradePlan
          currentAccount={currentAccount}
          onHide={onHide}
        />
      )
    case ModalName.WebPageURL:
      return (
        <WebPageURL
          eventId={currentEvent.id}
          urlPath={currentEvent.website?.urlPath}
          onHide={onHide}
        />
      )
    case ModalName.AccountUserForm:
      return (
        <AccountUserForm
          currentUser={currentUser}
          {...currentModal.params}
          onClose={onHide}
        />
      )
    case ModalName.SelectGuest:
      return (
        <SelectGuest
          eventId={currentEvent.id}
          filtered={currentModal.params?.filtered}
          onSelect={currentModal.params?.onSelect}
          {...currentModal.params}
          onClose={onHide}
        />
      )
    case ModalName.UpdateGuestStatus:
      return (
        <ChangeStatus
          guest={currentModal.params?.guest}
          updateGuest={currentModal.params?.updateGuest}
          selectedStatusValue={currentModal.params?.selectedStatusValue}
          onClose={onHide}
        />
      )
    case ModalName.FormForm:
      return (
        <FormForm
          onClose={onHide}
          event={currentEvent}
          onSuccess={currentModal.params?.onSuccess}
          form={currentModal.params?.form}
        />
      )
    case ModalName.QuestionForm:
      return (
        <QuestionModal
          onClose={onHide}
          eventId={currentEvent.id}
          questionId={currentModal.params?.questionId}
          onSuccess={currentModal.params?.onSuccess}
        />
      )
    case ModalName.AddFormQuestion:
      return (
        <AddFormQuestion
          onClose={onHide}
          event={currentEvent}
          blocks={currentModal.params?.blocks}
          addBlock={currentModal.params?.addBlock}
          selectedQuestionId={currentModal.params?.selectedQuestionId}
          position={currentModal.params?.position}
        />
      )
    case ModalName.EditMessaging:
      return (
        <EditMessaging
          event={currentEvent}
          onClose={onHide}
          formType={currentModal.params?.formType}
          defaultTranslations={currentModal.params?.defaultTranslations}
          updateEvent={currentModal.params?.updateEvent}
          form={currentModal.params?.form}
        />
      )
    case ModalName.NewTextMessage:
      return (
        <NewTextMessage
          event={currentEvent}
          onClose={onHide}
        />
      )
    case ModalName.EventActivityForm:
      return (
        <EventActivityForm
          eventId={currentEvent.id}
          editLocation={currentModal.params?.editLocation}
          onSave={currentModal.params?.onSave}
          onHide={onHide}
        />
      )
    case ModalName.EditTextRecipients:
      return (
        <EditTextRecipients
          onHide={onHide}
          event={currentEvent}
          onSave={currentModal.params?.onSave}
          textMessage={currentModal.params?.textMessage}
        />
      )
    case ModalName.PreviewTextMessage:
      return (
        <PreviewTextMessage
          onHide={onHide}
          event={currentEvent}
          textMessage={currentModal.params?.textMessage}
          enabled={currentModal.params?.enabled}
        />
      )
    case ModalName.InviteExhibitorOrAssignLicense:
      return (
        <InviteExhibitorOrAssignLicense
          onHide={onHide}
          mode={currentModal.params?.mode}
        />
      )
    default:
      return null
  }
}

export default Modals
