import React, { ReactNode } from "react"
import { Box, Flex, CloseButton } from "@chakra-ui/react"
import Icon from "components/elements/Icon"

type Props = {
  children: ReactNode
  onClose?: () => void
}

const PageWithDioboxLogo = ({ children, onClose }: Props) => (
  <Flex direction="column" bg="gray.200" h="100dvh" overflowY="scroll">
    {onClose && <CloseButton position="absolute" right={10} top={10} onClick={onClose} />}
    <Box h={110} color="gray.700">
      <Icon size={38} h={12} icon="diobox-logo" mx={10} my={8} />
    </Box>
    <Flex
      direction="column"
      align="center"
      justify="center"
      flex={1}
      color="gray.700"
      mb={50}
    >
      <Flex align="center" justify="center" flex={1} mb="auto">
        {children}
      </Flex>
    </Flex>
  </Flex>
)

export default PageWithDioboxLogo
