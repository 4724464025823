import React, { useState } from "react"
import {
  Flex, Stack, Box, Image, Heading,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import Button from "components/Buttons/Button"
import * as Yup from "yup"
import { EMAIL_VALIDATION_REGEX } from "utilities/constants"
import { yupResolver } from "@hookform/resolvers/yup"
import TOULinks from "components/TOULinks"
import {
  Route, Switch, useHistory, useLocation, useParams,
} from "react-router-dom"
import { checkLeadRetrievalUserEmail, createLeadRetrievalUser } from "api/events"
import {
  CreateLeadRetrievalUserResponse,
  LeadRetrievalUser,
  PublicDioboxEvent,
} from "sharedTypes"
import { leadRetrievalRegisterPath } from "utilities/routes"
import { useElements, useStripe } from "@stripe/react-stripe-js"
import { errorToast, successToast } from "utilities/toasts"
import useSearchParams from "services/useSearchParams"
import SegmentTitle from "./SegmentTitle"
import EmailScreen from "./Steps/EmailScreen"
import PersonalDataScreen from "./Steps/PersonalDataScreen"
import AlreadyHaveLicenseScreen from "./Steps/AlreadyHaveLicenseScreen"
import BuyLicenseScreen from "./Steps/BuyLicenseScreen"
import PaymentSuccessScreen from "./Steps/PaymentSuccessScreen"

export enum Step {
  PersonalData = "personal-data",
  AlreadyHaveLicense = "already-have-license",
  BuyLicense = "buy-license",
  PaymentSuccess = "payment-success",
}

type Props = {
  event: PublicDioboxEvent
}

const LeadRetrievalRegister = ({ event }: Props) => {
  const { subpath } = useParams<{ subpath: Step }>()
  const [leadRetrievalUser, setLeadRetrievalUser] = useState<LeadRetrievalUser | null>(null)

  const schema = Yup.lazy(() => {
    // If the subpath is not defined, we are on the first step
    if (!subpath) {
      return Yup.object().shape({
        email: Yup.string()
          .matches(EMAIL_VALIDATION_REGEX, "Invalid email format")
          .required("Email is required"),
        name: Yup.string().nullable(),
        password: Yup.string().nullable(),
      })
    }

    if (subpath === Step.BuyLicense) {
      return Yup.object().shape({
        email: Yup.string()
          .matches(EMAIL_VALIDATION_REGEX, "Invalid email format")
          .required("Email is required"),
        name: Yup.string().required(),
        password: Yup.string().nullable(),
        paymentCompleted: Yup.boolean().oneOf([true], "Payment is required"),
      })
    }

    return Yup.object().shape({
      email: Yup.string()
        .matches(EMAIL_VALIDATION_REGEX, "Invalid email format")
        .nullable(),
      name: Yup.string().required("Name is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      paymentCompleted: Yup.boolean().oneOf([true], "Payment is required"),
    })
  })

  const {
    control, handleSubmit, formState: { isValid, isSubmitting }, reset, getValues,
  } = useForm({
    defaultValues: {
      email: "", name: "", password: "", paymentCompleted: false,
    },
    resolver: yupResolver(schema),
  })
  const location = useLocation()
  const history = useHistory()
  const stripe = useStripe()!
  const elements = useElements()!
  const { email } = useSearchParams()
  const emailAddress = getValues("email") || email

  const {
    id: eventId,
    eventImageUrl,
    name,
    dateTimeLocation,
    leadRetrievalLicenseFee,
    showEventImage,
    eventImageScale,
  } = event
  const registrationBaseUrl = `${leadRetrievalRegisterPath()}/${eventId}`
  const baseUrl = `${window.location.protocol}//${window.location.host}`
  const hasEventImage = showEventImage && eventImageUrl
  const eventImageScaleWidth = 1332

  const confirmPayment = async (response: CreateLeadRetrievalUserResponse,
    LRUserEmail: string) => {
    await elements.submit()

    const result = await stripe.confirmPayment({
      elements,
      clientSecret: response.paymentIntentClientSecret,
      confirmParams: {
        return_url: `${baseUrl}${registrationBaseUrl}/${Step.PaymentSuccess}?email=${encodeURIComponent(LRUserEmail)}`,
      },
    })
    if (result.error) {
      errorToast({ title: result.error.message })
      reset({}, { keepValues: true })
    } else {
      successToast({ title: "Payment successful" })
    }
  }

  const handleClickOrEnterKey = handleSubmit(async (values) => {
    switch (subpath) {
      case undefined: {
        const { email: userEmail } = values as { email: string }
        const {
          data: { existingLicense, user },
        } = await checkLeadRetrievalUserEmail(eventId, userEmail)

        if (existingLicense && user) {
          setLeadRetrievalUser(user)
          history.push(`${registrationBaseUrl}/${Step.AlreadyHaveLicense}`)

          return null
        }

        if (user) {
          setLeadRetrievalUser(user)
          history.push(`${registrationBaseUrl}/${Step.BuyLicense}`)
        } else {
          history.push(`${registrationBaseUrl}/${Step.PersonalData}`)
        }

        return null
      }
      case Step.PersonalData: {
        const { data } = await createLeadRetrievalUser(
          eventId, values as LeadRetrievalUser,
        )

        return confirmPayment(data, values.email!)
      }
      case Step.BuyLicense: {
        if (!leadRetrievalUser || !values.name) {
          return null
        }

        const { data } = await createLeadRetrievalUser(eventId,
          { id: leadRetrievalUser.id, name: values.name })

        return confirmPayment(data, leadRetrievalUser.email!)
      }
      default:
        return null
    }
  })

  const handleKeyDown = (keyboardEvent: React.KeyboardEvent) => {
    if (keyboardEvent.key === "Enter" && isValid) {
      keyboardEvent.preventDefault()
      handleClickOrEnterKey()
    }
  }

  let submitButtonText
  switch (subpath) {
    case undefined: {
      submitButtonText = "Continue"
      break
    }
    case Step.PersonalData: {
      submitButtonText = "Purchase & Activate License"
      break
    }
    case Step.AlreadyHaveLicense: {
      submitButtonText = "Purchase & Activate License"
      break
    }
    case Step.BuyLicense: {
      submitButtonText = "Purchase & Activate License"
      break
    }
    case Step.PaymentSuccess: {
      submitButtonText = "Purchase & Activate License"
      break
    }
    default:
  }

  const path = `${leadRetrievalRegisterPath()}/:eventId`

  return (
    <Flex direction="column" bg="gray.50" h="100dvh" overflowY="scroll">
      <Flex my={12} flexDirection="column" alignItems="center" gap={4}>
        <Stack spacing={6} borderRadius="lg" bg="white" borderWidth={2} maxW={670} p={8} mx={4} overflow="hidden">
          <Flex flexDirection="column" alignItems="center" mx={-8} mt={-8}>
            {hasEventImage && (
            <Image
              src={`${eventImageUrl}?fit=crop&w=${eventImageScaleWidth * (eventImageScale / 100)}`}
              w={`${eventImageScale}%`}
              borderTopLeftRadius="lg"
              borderTopRightRadius="lg"
              alt={name}
            />
            )}
            <Heading mt={4} size="lg">{name}</Heading>
            <Box
              textAlign="center"
              dangerouslySetInnerHTML={{ __html: dateTimeLocation }}
              mb={3}
              fontSize="lg"
            />
          </Flex>
          <SegmentTitle leftText="Lead Retrieval App" />
          <Switch location={location}>
            <Route
              path={`${path}/${Step.PersonalData}`}
              render={() => (
                <PersonalDataScreen
                  control={control}
                  emailAddress={emailAddress}
                  leadRetrievalLicenseFee={leadRetrievalLicenseFee}
                />
              )}
            />
            <Route
              path={`${path}/${Step.AlreadyHaveLicense}`}
              render={() => {
                if (!leadRetrievalUser) {
                  history.replace(registrationBaseUrl)

                  return null
                }

                return <AlreadyHaveLicenseScreen leadRetrievalUser={leadRetrievalUser} />
              }}
            />
            <Route
              path={`${path}/${Step.BuyLicense}`}
              render={() => {
                if (!leadRetrievalUser) {
                  history.replace(registrationBaseUrl)

                  return null
                }

                return (
                  <BuyLicenseScreen
                    control={control}
                    leadRetrievalUser={leadRetrievalUser}
                    leadRetrievalLicenseFee={leadRetrievalLicenseFee}
                  />
                )
              }}
            />
            <Route
              path={`${path}/${Step.PaymentSuccess}`}
              render={() => (
                <PaymentSuccessScreen email={emailAddress} />
              )}
            />
            <Route
              path={path}
            >
              <EmailScreen control={control} onKeyDown={handleKeyDown} />
            </Route>
          </Switch>
        </Stack>
        {![Step.AlreadyHaveLicense, Step.PaymentSuccess].includes(subpath) && (
        <Button
          isDisabled={!isValid}
          onClick={handleClickOrEnterKey}
          isLoading={isSubmitting}
        >{submitButtonText}
        </Button>
        )}
        {[Step.PersonalData, Step.BuyLicense].includes(subpath) && (
        <TOULinks />
        )}
      </Flex>
    </Flex>
  )
}

export default LeadRetrievalRegister
